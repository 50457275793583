import React, {useEffect} from 'react'
import { AppContext } from '../context/AppContext'
import { gsap } from 'gsap'
import handleCloseBookInteraction from '../hooks/handleCloseBookInteraction'

export default function ShoppingNav() {

  const {
    isFullscreen,
    isMobile,
    animating,
    showShoppingList,
    products,
    activeProduct,
    setAreaActive,
    setMerchActive,
    setActiveProduct,
    videoRef,
    setLoadVideo,
    buttonRefs,
    floorLogoMesh,
    bookCoverMesh,
    bookEndMesh,
    closeBookMesh
  } = React.useContext(AppContext)
  
  // shopping list


  useEffect(() => {
    if (activeProduct && window.innerWidth <= 768) {
      const activeProductIndex = products.findIndex(product => product.id === activeProduct);
      const activeButtonRef = buttonRefs[activeProductIndex];
      if (activeButtonRef && activeButtonRef.current) {
        activeButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  }, [activeProduct]);

  const handleShopButtonClick = (id) => {
    if (activeProduct !== id) {
      if(!isMobile) {animating.current = true}
      setAreaActive(true);
      setMerchActive(true);
    }
    setActiveProduct(id);
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setLoadVideo(false);
    handleCloseBookInteraction(
      null, 
      floorLogoMesh,
      bookCoverMesh,
      bookEndMesh,
      closeBookMesh,
    );

     gsap.to('.popup-container', { duration: 1, x:0, y: 0, ease: 'expo.inOut', delay: 0.25 })

  }

  const handleHomeButtonClick = () => {
    animating.current = false;
    setAreaActive(true);
    setMerchActive(false);
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setLoadVideo(false);
    handleCloseBookInteraction(
      null, 
      floorLogoMesh,
      bookCoverMesh,
      bookEndMesh,
      closeBookMesh,
    );
    if(isMobile) {
      gsap.to('.popup-container', { duration: 0.1, y: 700, ease: 'expo.inOut' })
    } else {
      gsap.to('.popup-container', { duration: 0.1, x: -700, ease: 'back' })
    }
    setActiveProduct(null)
   
  }


  return (
    <>
      <div id="shopping-list" className={showShoppingList && isFullscreen ? 'active' : ''}>
      
      {
          (() => {
            const labelGroups = products.reduce((acc, product, index) => {
              if (!acc[product.label]) {
                acc[product.label] = [];
              }
              acc[product.label].push({ product, index });
              return acc;
            }, {});

            return Object.keys(labelGroups).map(label => (
              <React.Fragment key={label}>
                {label && label !== 'GIFT' && <div className="product-label">{label}</div>}
                <div className="product-group">
                  {labelGroups[label].map(({ product, index }) => (
                    <button
                      key={product.id}
                      ref={buttonRefs[index]}
                      aria-label={`Shop the ${product.title}`}
                      tabIndex={index + 1}
                      id={product.id}
                      title={`Shop the ${product.title}`}
                      className={`shopping-item ${(product.label).toLowerCase()} ${activeProduct === product.id ? 'active-product' : ''}`}
                      onClick={() => handleShopButtonClick(product.id)}
                    >
                      <span className="shop-image">
                        {product.iconPath ? <img src={product.iconPath} alt={product.title} /> :
                          (product.imagePath && <img src={product.imagePath} alt={product.title} />)
                          }
                      </span>
                      <span className="shop-label">
                        <span className="item-title" dangerouslySetInnerHTML={{ __html: product.title }} />
                      </span>
                    </button>
                  ))}
                </div>
              </React.Fragment>
            ));
          })()
        }
      </div>
      <nav className="shop-navbar">
        <button
          aria-label="Exit out of close-up view"
          tabIndex={products.length + 1} 
          className={!isFullscreen ? 'nav-item disabled' : (animating.current ? 'nav-item active' : 'nav-item')}
          onClick={handleHomeButtonClick}
          title="Return to the Shop">
            <span className="btn-label">SHOP</span></button>
      </nav>
    </>
  )
}