import HolidayShop from './static/HolidayShop'

export default function Static() {
  const scrollToContent = () => {
    const target = document.getElementById('callout-content');
    target.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div className="flex-container flex-column red-bkg">
          <img 
          className='scrolldown-arrow'
          src="/ui/double-arrow-down.svg" 
          aria-label="scroll down to explore more" 
          alt="Scroll down" 
          width="60"
          style={{ cursor: 'pointer', margin: '1rem 0' }} 
          onClick={scrollToContent}  />
      </div>
      <div id="callout-content">
        <div className="flex-container flex-column red-bkg">
          <h1 style={{margin: '0.5rem', textAlign: 'center', lineHeight: '1.1'}}>WELCOME TO THE SONIC® HOLIDAY SHOP</h1>
        </div>
        <HolidayShop />
      </div>
    </>
  );
}